:root{
    --faysal:#18407c;
    --islamic:#579209;
}
#faysallogo{
  width: 230px;
}
main{
  height: 100vh;
  /* padding-top: 15px; */
  width: 100vw;
}

@media only screen and (max-width: 600px) {
    #mobidiv {
      display:none;
    }
    #faysallogo{
      width: 200px;
    }
    #adminlogo{
      display: none;
    }
  }